<template>
  <!-- 今日关注页面 -->
  <div class="attention_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>{{ pageTitle }}</span>
      </div>
    </div>

    <div class="attention_list">
      <div class="attention_date">
        <div class="date_box">
          <div class="startTime" @click="showStartTimePicker = true">
            <span class="time" v-if="startTime">{{
              startTime | dateFilter_YY_MM_DD(2)
            }}</span>
            <span v-else>开始时间</span>
          </div>
          <div class="line">~</div>
          <div class="endTime" @click="showEndTimePicker = true">
            <span class="time" v-if="endTime">{{
              endTime | dateFilter_YY_MM_DD(2)
            }}</span>
            <span v-else>结束时间</span>
          </div>
          <div
            class="date_search"
            @click="
              list = [];
              onRefresh();
            "
          >
            <img class="auto_img" src="../../assets/images/搜索.png" alt="" />
          </div>
        </div>
      </div>
      <div class="attention_list_scroll">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="attention_item"
              v-for="(item, index) in list"
              :key="index"
              @click="
                $router.push({
                  name: 'AttentionOrder',
                  params: item
                })
              "
            >
              <div class="item_left">
                <div class="location">{{ item.parkingName }}</div>
                <div class="parkingTime">
                  停车时长：{{ item.timeDifference }}
                </div>
                <div class="leaveTime">
                  出场时间：{{
                    item.exitOperatorTime | dateFilter_yy_MM_dd_hh_mm_ss
                  }}
                </div>
              </div>
              <div class="item_right">
                <span :class="pageClassName">{{
                  pageState == 40 ? item.arrearAmount : item.orderAmount
                }}</span>
                元
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div class="attention_total">
        {{ pageState == 40 ? "欠费总额" : "减免总额" }}
        <span :class="pageClassName">{{ totalAmount }}</span> 元
      </div>
      <div class="attention_total_box"></div>
    </div>

    <van-popup v-model="showStartTimePicker" round position="bottom">
      <van-datetime-picker
        v-model="startTime"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showStartTimePicker = false"
        @confirm="onStartTimeConfirm"
      />
    </van-popup>

    <van-popup v-model="showEndTimePicker" round position="bottom">
      <van-datetime-picker
        v-model="endTime"
        type="date"
        title="选择年月日"
        :min-date="startTime ? startTime : minDate"
        :max-date="maxDate"
        @cancel="showEndTimePicker = false"
        @confirm="onEndTimeConfirm"
      />
    </van-popup>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
let newDate = new Date();
export default {
  data: () => ({
    list: [],
    loading: false,
    finished: false,
    refreshing: false,

    pageState: 0, //页面状态
    pageTitle: null, //导航栏标题
    requestParams: {}, //请求参数
    // requestUrl: "", //请求地址
    startTime: null, //开始时间
    endTime: null, //结束时间
    total: 0, //总条数
    totalAmount: 0, //总金额
    pageNum: 1, //页码
    pageSize: 10, //每页数量
    showStartTimePicker: false, //开始时间弹出层
    showEndTimePicker: false, //结束时间弹出层
    minDate: new Date(newDate.getFullYear(), newDate.getMonth() - 12, 1), //最小时间
    maxDate: new Date(newDate.getFullYear(), newDate.getMonth() + 12, 1) //最大时间
  }),
  methods: {
    /**
     * 开始时间选择器确认函数
     */
    onStartTimeConfirm(val) {
      this.startTime = val;
      this.showStartTimePicker = false;
    },

    /**
     * 结束时间选择器确认函数
     */
    onEndTimeConfirm(val) {
      this.endTime = val;
      this.showEndTimePicker = false;
    },

    /**
     * 添0操作
     */
    timeNumFormat(num) {
      return num < 10 ? "0" + num : num;
    },

    /**
     * 列表加载函数
     */
    onLoad() {
      if (this.startTime) {
        this.requestParams.startTime = `${this.startTime.getFullYear()}-${this.timeNumFormat(
          this.startTime.getMonth() + 1
        )}-${this.timeNumFormat(this.startTime.getDate())}`;
      }

      if (this.endTime) {
        this.requestParams.endTime = `${this.endTime.getFullYear()}-${this.timeNumFormat(
          this.endTime.getMonth() + 1
        )}-${this.timeNumFormat(this.endTime.getDate())}`;
      }
      console.log(this.requestParams);
      // this.$get(this.requestUrl, this.requestParams)
      this.$api
        .getTodayFocusList(this.requestParams)
        .then(res => {
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
          }

          this.total = res.data.total;
          this.totalAmount = res.data.totalAmount;

          this.requestParams.pageNum++;
          res.data.rows.forEach(item => {
            this.list.push(item);
          });

          this.loading = false;

          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
        });
    },

    /**
     * 列表刷新操作
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.requestParams.pageNum = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp
    }),

    //页面状态类名
    pageClassName() {
      if (this.pageState == 20) {
        return "free";
      } else if (this.pageState == 21) {
        return "whiteList";
      } else if (this.pageState == 40) {
        return "arrear";
      }

      return "";
    }
  },

  created() {
    let { pageState } = this.$route.query;
    this.pageState = pageState;

    // this.requestUrl = this.$config.TL_CHARGE_STATUS_GET_TODAY_FOCUS_LIST;
    if (this.pageState == 20) {
      this.pageTitle = "免费车辆";
      this.requestParams.type = 20;
    } else if (this.pageState == 21) {
      this.pageTitle = "白名单车辆";
      this.requestParams.type = 21;
    } else if (this.pageState == 40) {
      this.pageTitle = "欠费车辆";
      this.requestParams.type = 40;
    }
  }
};
</script>

<style lang="less" scoped>
.attention_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.attention_list {
  flex: 1;
  width: 100%;
  padding: 14px;
  background-color: #edf0f7;
  position: relative;
  overflow: hidden;

  .attention_list_scroll {
    overflow: scroll;
    height: 100%;
    width: calc(100% + 80px);
    padding: 0 40px;
    transform: translateX(-40px);
  }

  .attention_item {
    width: 100%;
    padding: 10px 14px;
    background-color: #fff;
    margin-bottom: 14px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;

    .item_left {
      .location {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .parkingTime,
      .leaveTime {
        font-size: 12px;
        color: #a0a0a0;
      }

      .parkingTime {
        margin-bottom: 8px;
      }
    }

    .item_right {
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-weight: 600;
      color: #9b9b9b;
      font-size: 12px;

      span {
        color: #fa5858;
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }

  .arrear {
    color: #ff0000 !important;
  }

  .free {
    color: #40c09d !important;
  }
  .whiteList {
    color: #15aacd !important;
  }

  .attention_total {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    padding: 8px 24px;
    color: #fff;
    background-color: #48484b;
    border-radius: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    span {
      font-size: 18px;
      margin: 0 8px;
      color: #e75151;
      font-weight: 600;
    }
  }

  .attention_total_box {
    height: 50px;
  }
}

.attention_date {
  width: 100%;
  padding: 12px;

  .date_box {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #15aabf;
    padding: 6px;
    display: flex;
    align-items: center;
    color: #c0aeb6;
    font-size: 14px;

    .line {
      font-size: 16px;
    }

    .startTime,
    .endTime {
      flex: 1;
      text-align: center;

      .time {
        color: #333;
      }
    }

    .date_search {
      width: 20px;
      margin-right: 6px;
    }
  }
}
</style>
